import React from "react";
import "./HeroSection.css";
import Header from "./Header";

const index = () => (
  <div className="bg-blend-overlay" id="home">
    <section className="creative-hero--section">
      <div className="video-container">
        <video autoPlay muted loop playsInline className="bg-video absolute">
          <source src="/media/bg-video.mp4" type="video/mp4" />
          <div className="bg bg-image"></div>
        </video>
      </div>
      <div className="bg-overlay"></div>
      <div
        className="auto-container relative flex flex-col md:gap-0 gap-28"
        style={{
          height: "100vh",
        }}
      >
        <Header />
        <div className="content-box lg:px-64 sm:px-32 xs:px-8 px-8 flex-auto">
          <h1 className="text-4xl leading-[50px] sm:leading-[75px] sm:text-5xl lg:text-6xl lg:leading-[100px] text-[#273d4b] text-center font-bold">
            Empowering parents with every heartbeat
          </h1>
        </div>
      </div>
    </section>
  </div>
);

export default index;
