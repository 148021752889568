import React from "react";

const Comparison = () => {
  const features = [
    { name: "Sleep analytics", happySense: "Yes", others: "Few" },
    { name: "Remote live feed", happySense: "Yes", others: "Few" },
    { name: "Cry detection", happySense: "Yes", others: "Few" },
    { name: "Face covered", happySense: "Yes", others: "No" },
    { name: "Vitals", happySense: "Yes", others: "No" },
    { name: "Sharp object detection", happySense: "Yes", others: "No" },
    { name: "Stranger detection", happySense: "Yes", others: "No" },
    { name: "Air quality", happySense: "Yes", others: "No" },
    { name: "Customizable alerts", happySense: "Yes", others: "Few" },
    { name: "Lullabies", happySense: "Yes", others: "Few" },
    { name: "Family sharing", happySense: "Yes", others: "Few" },
    { name: "Two-way communication", happySense: "Yes", others: "Few" },
    { name: "Health tips", happySense: "Yes", others: "No" },
  ];
  return (
    <div className=" bg-white min-h-screen px-10 pb-36 max-sm:pb-16 max-sm:px-0 ">
      <h1 className="text-4xl md:text-5xl my-10 md:mb-20 font-bold max-sm:p-10">
        We are light years ahead of the competition
      </h1>
      <div
        className=" relative min-h-screen flex justify-center items-center bg-cover bg-center z-0 "
        style={{
          backgroundColor: "#ec6c69",
        }}
      >
        <div className="bg-white bg-opacity-80 p-10 rounded-lg shadow-lg max-w-7xl w-full z-20">
          <table className="w-full text-center border-collapse">
            <thead>
              <tr>
                <th className="border-b-2 md:text-3xl p-2 text-gray-500">
                  Features
                </th>
                <th className="border-b-2 md:text-3xl p-2 text-[#273d4b]">
                  HappySense
                </th>
                <th className="border-b-2 md:text-3xl p-2 text-gray-500">
                  Others
                </th>
              </tr>
            </thead>
            <tbody>
              {features.map((feature) => (
                <tr key={`comparison-${feature.name}`}>
                  <td className="border-b md:text-2xl p-2 text-gray-500 font-semibold">
                    {feature.name}
                  </td>
                  <td className="border-b md:text-2xl p-2 text-[#273d4b]">
                    {feature.happySense}
                  </td>
                  <td className="border-b md:text-2xl p-2 text-gray-500">
                    {feature.others}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Comparison;
