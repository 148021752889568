import React from "react";

const JoinWaitlist = () => {
  const [email, setEmail] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setMessage("");
    const response = await fetch(
      "https://script.google.com/macros/s/AKfycbxrBUzm9IAFqn1tCm2jCqDfzsfA4CcAiOT9RcyzadiB_dV8sJlc078hQNjpAy43iIDT/exec",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          email: email,
        }),
      }
    );

    if (response.ok) {
      setMessage("You have successfully joined the waitlist!");
      setEmail("");
    } else {
      setMessage("Something went wrong. Please try again.");
    }

    setIsLoading(false);
  };
  return (
    <div className="flex flex-col items-center justify-center min-h-96 bg-gray-50 px-4 py-40">
      <h1 id="join" className="text-4xl md:text-5xl font-bold text-center mb-4">
        Join the waitlist
      </h1>
      <p className="text-lg md:text-xl text-center text-gray-600 mb-8 max-w-2xl">
        Be the first to receive exclusive updates, special offers, and early
        access to exciting new features from Happysense. Don't miss out on the
        opportunity to stay ahead and get the best deals.
      </p>
      {message ? (
        <p className="mt-4 text-center font-semibold" aria-live="polite">
          {message}
        </p>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="flex flex-row items-center justify-center w-full max-w-lg"
          aria-labelledby="join"
        >
          <input
            type="email"
            placeholder="Enter your email"
            className="flex-1 p-3 rounded-lg border border-gray-300 mb-0 md:mr-4 min-w-0"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button
            className="bg-[#273d4b] text-white py-3 px-6 rounded-lg font-semibold w-36"
            disabled={isLoading}
          >
            {isLoading ? "Joining..." : "Join list"}
          </button>
        </form>
      )}
      <div className="flex items-center mt-8 space-x-2">
        {/* Example avatars */}
        <img
          src="https://i.pravatar.cc/300"
          alt="User avatar"
          className="w-10 h-10 rounded-full"
        />
        <img
          src="https://i.pravatar.cc/200"
          alt="User avatar"
          className="w-10 h-10 rounded-full"
        />
        <img
          src="https://i.pravatar.cc/100"
          alt="User avatar"
          className="w-10 h-10 rounded-full"
        />
      </div>
      <p className="text-sm text-gray-600 mt-4">
        Join 5k others and keep yourself updated.
      </p>
    </div>
  );
};

export default JoinWaitlist;
