import React from "react";
import "./EmotionConnect.css";
import { FaUserSecret } from "react-icons/fa";
import { PiKnifeFill } from "react-icons/pi";
import { FaRegSadCry } from "react-icons/fa";
import { FaHeadSideMask } from "react-icons/fa";

const EmotionalConnect = () => {
  const [activeSectionKey, setActiveSectionKey] =
    React.useState("face-covered");
  const sections = [
    {
      key: "face-covered",
      Icon: FaHeadSideMask,
    },
    {
      key: "sharp-object",
      Icon: PiKnifeFill,
    },
    {
      key: "stranger",
      Icon: FaUserSecret,
    },
    {
      key: "cry",
      Icon: FaRegSadCry,
    },
  ];
  const meta = {
    "face-covered": {
      title: "Face Covered",
      description:
        "Cutting-edge AI technology detects if the baby's face is covered with something",
      imgUrl: "/media/images/face-covered.jpg",
      colorClass: "text-white",
    },
    "sharp-object": {
      title: "Sharp object detection",
      description: "AI detects sharp objects for proactive safety measures",
      imgUrl: "/media/images/sharp-object.jpg",
      colorClass: "text-black",
    },
    stranger: {
      title: "Stranger detection",
      description:
        "AI alerts for unfamiliar individuals in monitored areas, ensuring security ",
      imgUrl: "/media/images/stranger-detection.jpg",
      colorClass: "text-black",
    },
    cry: {
      title: "Cry detection",
      description:
        "Responsive cry detection alerts you instantly, ensuring peace of mind",
      imgUrl: "/media/images/cry-detection.jpg",
      colorClass: "text-black",
    },
  };
  return (
    <div className="thoughtful" id="features">
      <h2 className="thoughtful__title text-4xl md:text-5xl">Watchful Eyes</h2>
      <div className="thoughtful-menu">
        {sections.map((section) => (
          <div
            onClick={() => setActiveSectionKey(section.key)}
            className={`thoughtful-menu__item thoughtful-menu__item--1 ${
              activeSectionKey === section.key && "thoughtful-active"
            }`}
            key={section.key}
          >
            <section.Icon
              size={45}
              color={activeSectionKey === section.key ? "gray" : "#EBF4F6"}
            />
          </div>
        ))}
      </div>
      <div className="bg-white pb-28">
        <div className="thoughtful-main">
          <div className="thoughtful-item" id="thoughtful-display">
            <div className="px-5 md:px-20 pt-5 pb-12 flex flex-col gap-4">
              <h3
                className={
                  "text-center text-3xl md:text-4xl font-bold sm:text-3xl "
                }
              >
                {meta[activeSectionKey]?.title}
              </h3>
              <p className="font-large thoughtful-cover__wording--subtitle text-center md:text-3xl sm:text-xl text-md">
                {meta[activeSectionKey]?.description}
              </p>
            </div>
            <div className="thoughtful-template-item">
              <img
                data-src={meta[activeSectionKey]?.imgUrl}
                className="thoughtful-cover__desktop-img lozad"
                width="870"
                height="430"
                loading="lazy"
                alt=""
                src={meta[activeSectionKey]?.imgUrl}
                data-loaded="true"
              />
              <img
                src={meta[activeSectionKey]?.imgUrl}
                className="thoughtful-cover__mobile-img lozad"
                width="100%"
                height="auto"
                loading="lazy"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmotionalConnect;
