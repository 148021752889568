import React from "react";
import { IoMdClose } from "react-icons/io";

const SideHeader = ({ toggleMenu, menuOpen, menuItems }) => {
  return (
    <div
      className={`menu-overlay ${
        menuOpen ? "menu-open" : "menu-closed"
      } z-50 fixed top-0 left-0 w-full h-full bg-white flex flex-col items-center justify-center transition-transform duration-300`}
    >
      <div className="header absolute right-10 top-10 left-10 flex justify-between items-center">
        <img src="./media/logo.png" alt="happysense.ai" className="w-28 h-28" />
        <button
          className="h-10 w-10 flex items-center justify-center focus:outline-none"
          aria-label="Toggle Navigation Menu"
          onClick={toggleMenu}
        >
          <IoMdClose color="#273d4b" size={40} />
        </button>
      </div>
      {menuItems.map((item) => (
        <a
          className="menu-item text-[#273d4b] text-2xl mb-8"
          href={`#${item.key}`}
          key={item.key}
          onClick={toggleMenu}
        >
          {item.title}
        </a>
      ))}
      <a
        href="#join"
        className="text-white bg-[#273d4b] hover:[#273d4b] rounded-lg text-xl px-5 py-2.5 me-2"
        onClick={toggleMenu}
      >
        Join the waitlist
      </a>
    </div>
  );
};

export default SideHeader;
