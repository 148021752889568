import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { overlay } from ".";

const WatchfulEyes = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const width = window.innerWidth;
  return width > 800 ? (
    <div className="wrapper">
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop"
          loading="lazy"
          width="870"
          height="350"
          alt=""
          src="/media/images/lullabies.jpg"
          data-loaded="true"
        />
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/lullabies.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-white content__title--half ">
            Lullabies
          </h4>
          <div className="content__description">
            Soothe your baby with a selection of built-in lullabies, creating a
            calming environment
          </div>
          <div></div>
        </div>
      </div>
      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/communication.jpg"
          loading="lazy"
          width="427"
          height="350"
          alt=""
          src="/media/images/communication.jpg"
          data-loaded="true"
        />

        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/communication.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-gray-600">
            Two Way Communication
          </h4>
          <div className="content__description">
            Stay connected with your baby using two-way audio for comforting
            words at any time.
          </div>
          {/* <a
            className="button content__button button-tertiary button-normal"
            href="https://us.getcubo.com/pages/cuboai-feature-safety#safety-micro-motion"
          >
            Learn more
          </a> */}
          <div></div>
        </div>
      </div>
      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/family-sharing.jpg"
          loading="lazy"
          width="427"
          height="350"
          alt=""
          src="/media/images/family-sharing.jpg"
          data-loaded="true"
        />

        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/family-sharing.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-white">Family sharing</h4>
          <div className="content__description">
            Effortlessly share baby monitoring with loved ones for collective
            peace of mind.
          </div>
          <div></div>
        </div>
      </div>
    </div>
  ) : (
    <Slider {...settings}>
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/lullabies.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-white content__title--half ">
            Lullabies
          </h4>
          <div className="content__description">
            Soothe your baby with a selection of built-in lullabies, creating a
            calming environment
          </div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/communication.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-gray-600">
            Two Way Communication
          </h4>
          <div className="content__description">
            Stay connected with your baby using two-way audio for comforting
            words at any time.
          </div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="https://www.shutterstock.com/shutterstock/photos/372342145/display_1500/stock-photo-happy-grandparents-playing-with-their-grandson-at-home-372342145.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-watchful content">
          <h4 className="content__title text-white">Family sharing</h4>
          <div className="content__description">
            Effortlessly share baby monitoring with loved ones for collective
            peace of mind.
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default WatchfulEyes;
