import "./App.css";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import FeaturesSection from "./components/Features";
import EmotionalConnect from "./components/EmotionalConnect";
import Comparison from "./components/Comparison";
import JoinWaitlist from "./components/JoinWaitlist";
import Footer from "./components/Footer";
import Header from "./components/Header";

function App() {
  return (
    <div className="App bg-white min-h-screen min-w-screen scroll-smooth">
      <Header />
      <HeroSection />
      <AboutSection />
      <EmotionalConnect />
      <FeaturesSection />
      <Comparison />
      <JoinWaitlist />
      <Footer />
    </div>
  );
}

export default App;
