import React, { useEffect, useRef, useState } from "react";
import "./AboutSection.css";

const AboutSection = () => {
  const descriptionTextRef = useRef(null);
  const [scrollY, setScrollY] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);

  const handleScroll = () => {
    setScrollY(window.scrollY);
  };

  function getDivisorAndOffset(inputWidth) {
    let divisor, offset;

    // Calculate Divisor
    if (inputWidth >= 300 && inputWidth < 600) {
      divisor = (1 / 600) * (inputWidth - 300) + 5;
    } else if (inputWidth >= 600 && inputWidth < 900) {
      divisor = (1 / 300) * (inputWidth - 600) + 5.5;
    } else if (inputWidth >= 900 && inputWidth < 1200) {
      divisor = (1 / 600) * (inputWidth - 900) + 5.75;
    } else if (inputWidth >= 1200 && inputWidth < 1500) {
      divisor = (1 / 600) * (inputWidth - 1200) + 6.25;
    } else if (inputWidth >= 1500 && inputWidth <= 1800) {
      divisor = (1 / 600) * (inputWidth - 1500) + 6.5;
    } else if (inputWidth < 300) {
      divisor = 4; // Default value for inputWidth < 300
    } else if (inputWidth > 1800) {
      divisor = 7.5; // Default value for inputWidth > 1800
    } else {
      divisor = 4.5; // Default value if none of the conditions are met
    }

    // Calculate Offset
    if (inputWidth >= 300 && inputWidth < 600) {
      offset = ((70 - 85) / (600 - 300)) * (inputWidth - 300) + 85;
    } else if (inputWidth >= 600 && inputWidth < 900) {
      offset = ((60 - 70) / (900 - 600)) * (inputWidth - 600) + 70;
    } else if (inputWidth >= 900 && inputWidth < 1200) {
      offset = (-80 / 300) * (inputWidth - 900) + 60;
    } else if (inputWidth >= 1200 && inputWidth < 1500) {
      offset = (-10 / 300) * (inputWidth - 1200) + 55;
    } else if (inputWidth >= 1500 && inputWidth <= 1800) {
      offset = (-15 / 300) * (inputWidth - 1500) + 45;
    } else if (inputWidth < 300) {
      offset = 85; // Default value for inputWidth < 300
    } else if (inputWidth > 1800) {
      offset = 40; // Default value for inputWidth > 1800
    } else {
      offset = 85; // Default value if none of the conditions are met
    }

    return { divisor, offset };
  }

  const getColoredText = (text, scrollY) => {
    const letters = text.split("");
    const coloredLetters = letters.map((letter, index) => {
      let { divisor, offset } = getDivisorAndOffset(width);

      const colorValue =
        scrollY / divisor >= index + offset ? "#273d4b" : "lightgray";
      return (
        <span key={index} style={{ color: colorValue }}>
          {letter}
        </span>
      );
    });
    return coloredLetters;
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="py-32 px-10 sm:px-20 xl:px-32" id="about">
      <h1 className="max-sm:text-center lg:text-6xl text-4xl text-left mb-10">
        Our Story
      </h1>
      <div
        className="flex justify-between flex-col xl:flex-row gap-10 w-full m-auto"
        style={{
          animation: "fadeInUp 1s ease-in-out 0.6s forwards",
        }}
        å
      >
        <div className="flex-auto max-w-[1000px]">
          <p className="max-sm:text-center text-[#273d4b] md:text-3xl md:leading-10 text-xl leading-8 text-left">
            An innovative leader in infant care technology, HAPPYSENSE offers
            unparalleled peace of mind with its advanced AI powered monitoring
            system. Designed for modern parenting, it ensures safety and comfort
            for your baby, making life easier for you. Simple to set up and
            effortless to use, it is very powerful.
          </p>
        </div>
        <div className="flex-auto max-xl:pt-9 min-w-48 xl:min-w-[30rem] flex items-center justify-center">
          <div>
            <a
              className="cursor-pointer px-6 py-5 min-w-44 border-2 rounded-full text-[#273d4b] border-[#273d4b] xs:text-xl md:text-2xl"
              href="#features"
            >
              What HappySense does
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
