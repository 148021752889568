import React, { useState } from "react";
import "./Header.css";
import SideHeader from "../../SideHeader";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    {
      title: "Home",
      key: "home",
    },
    {
      title: "About",
      key: "about",
    },
    {
      title: "Features",
      key: "features",
    },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <>
      <div className="w-full h-30 flex items-center justify-between px-10 pt-10">
        <img src="./media/logo.png" alt="happysense.ai" className="w-40 h-40" />
        <div className="menu gap-6 md:flex font-semibold items-center hidden">
          {menuItems.map((item) => (
            <a
              className="menu-item text-[#273d4b]"
              href={`#${item.key}`}
              key={item.key}
            >
              {item.title}
            </a>
          ))}
          <a
            href="#join"
            className="text-white bg-[#273d4b] hover:[#273d4b] font-medium rounded-lg text-sm px-5 py-2.5 me-2"
          >
            Join the waitlist
          </a>
        </div>
        <div className="flex md:hidden">
          <button
            className="h-10 w-10 flex items-center justify-center border-[#273d4b] rounded-none border-solid focus:outline-none"
            aria-label="Toggle Navigation Menu"
            onClick={toggleMenu}
          >
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 6h16M4 12h16M4 18h16"
                stroke="#273d4b"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
      <SideHeader
        menuItems={menuItems}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
      />
    </>
  );
};

export default Header;
