import React, { useState, useEffect } from "react";
import { IoMdClose } from "react-icons/io";
import SideHeader from "../SideHeader";

const Header = () => {
  const [showHeader, setShowHeader] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [menuOpen, setMenuOpen] = useState(false);

  const menuItems = [
    {
      title: "Home",
      key: "home",
    },
    {
      title: "About",
      key: "about",
    },
    {
      title: "Features",
      key: "features",
    },
  ];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (currentScrollY > window.innerHeight) {
        if (currentScrollY < lastScrollY) {
          setShowHeader(true);
        } else {
          setShowHeader(false);
        }
      } else {
        setShowHeader(false);
      }
      setLastScrollY(currentScrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <>
      <header
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundColor: "#fff",
          color: "#273d4b", // Adjusted color for better visibility
          padding: "30px",
          transform: showHeader ? "translateY(0)" : "translateY(-100%)",
          transition: "transform 0.3s ease-in-out",
          boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Added shadow for better separation from content
        }}
        className="z-40"
      >
        <nav className="flex justify-center items-center">
          <div className="menu gap-6 md:flex font-semibold items-center hidden">
            {menuItems.map((item) => (
              <a
                className="menu-item text-[#273d4b] text-2xl"
                href={`#${item.key}`}
                key={item.key}
                style={{ padding: "0 10px", textDecoration: "none" }}
              >
                {item.title}
              </a>
            ))}
            <a
              href="#join"
              className="text-white bg-[#273d4b] hover:[#273d4b] rounded-full px-7 py-3.5 me-2 text-2xl"
            >
              Join the waitlist
            </a>
          </div>
          <div className="flex md:hidden justify-between w-full items-center">
            <img
              src="./media/logo.png"
              alt="happysense.ai"
              className="w-16 h-16"
            />
            <div>
              <button
                className="h-10 w-10 flex items-center justify-center border-[#273d4b] rounded-none border-solid focus:outline-none"
                aria-label="Toggle Navigation Menu"
                onClick={toggleMenu}
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 6h16M4 12h16M4 18h16"
                    stroke="#273d4b"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </nav>
      </header>
      <SideHeader
        menuItems={menuItems}
        menuOpen={menuOpen}
        toggleMenu={toggleMenu}
      />
    </>
  );
};

export default Header;
