import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { overlay } from ".";

const AlertsHub = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const width = window.innerWidth;
  return width > 800 ? (
    <div className="wrapper">
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/immediate-awareness.jpg"
          loading="lazy"
          width="870"
          height="350"
          alt=""
          src="/media/images/immediate-awareness.jpg"
          data-loaded="true"
        />
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/immediate-awareness.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title  content__title--half ">
            Immediate Awareness
          </h4>
          <div className="content__description">
            Receive instant alerts when your baby needs attention, so you can
            respond quickly.
          </div>
          {/* <a
            className="button content__button button-tertiary button-normal"
            href="https://us.getcubo.com/pages/cuboai-feature-safety#safety-temp-detection"
          >
            Learn more
          </a> */}
          <div></div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/safety.jpg"
          loading="lazy"
          width="427"
          height="350"
          alt=""
          src="/media/images/safety.jpg"
          data-loaded="true"
        />

        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/safety.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title ">Safety Alerts</h4>
          <div className="content__description">
            Be notified of any concerns, from sharp objects to unfamiliar faces,
            ensuring a safety for your little one.
          </div>
          <div></div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/stress-reduction.jpg"
          loading="lazy"
          width="870"
          height="350"
          alt=""
          src="/media/images/stress-reduction.jpg"
          data-loaded="true"
        />
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/stress-reduction.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title ">Stress Reduction</h4>
          <div className="content__description">
            Our alerts are designed to ease your mind so you can focus on what
            matters most.
          </div>
          <div></div>
        </div>
      </div>
    </div>
  ) : (
    <Slider {...settings} className="relative">
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/immediate-awareness.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title  content__title--half ">
            Immediate Awareness
          </h4>
          <div className="content__description">
            Receive instant alerts when your baby needs attention, so you can
            respond quickly.
          </div>
        </div>
      </div>
      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="https://www.shutterstock.com/shutterstock/photos/2374655165/display_1500/stock-photo-a-small-child-reaches-for-dishes-in-the-dishwasher-2374655165.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title ">Safety Alerts</h4>
          <div className="content__description">
            Be notified of any concerns, from sharp objects to unfamiliar faces,
            ensuring a safety for your little one.
          </div>
        </div>
      </div>
      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="https://www.shutterstock.com/shutterstock/photos/2433173109/display_1500/stock-photo-young-businesswoman-using-a-cellphone-while-working-on-a-laptop-in-an-office-alone-young-2433173109.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-alerts content">
          <h4 className="content__title ">Stress Reduction</h4>
          <div className="content__description">
            Our alerts are designed to ease your mind so you can focus on what
            matters most.
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default AlertsHub;
