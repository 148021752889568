import React from "react";
import "./Features.css";
import { IoAlertCircle } from "react-icons/io5";
import { FaHandHoldingHeart, FaEye } from "react-icons/fa";
import WatchfulEyes from "./WatchfulEyes";
import HarnessingAI from "./HarnessingAI";
import AlertsHub from "./AlertsHub";

const FeaturesSection = () => {
  const [activeSectionKey, setActiveSectionKey] = React.useState("ai");
  const sections = [
    {
      key: "ai",
      title: "AI Guardian",
      Icon: FaEye,
      Component: HarnessingAI,
    },
    {
      key: "alerts",
      title: "Stay Informed",
      Icon: IoAlertCircle,
      Component: AlertsHub,
    },
    {
      key: "watchful",
      title: "Soulful Connection",
      Icon: FaHandHoldingHeart,
      Component: WatchfulEyes,
    },
  ];
  const ActiveSection = sections.filter(
    (section) => section.key === activeSectionKey
  )[0];
  return (
    <div className="component-feature bg-white min-h-screen p-10 pb-36 max-sm:pb-16 max-sm:px-0">
      <h1 className="text-4xl md:text-5xl mb-10 md:mb-20 font-bold max-sm:p-10">
        Discover our thoughtful monitoring tools
      </h1>
      <div className="feature-main ">
        <div className="badge">
          {sections.map((section, index) => (
            <div key={`section-${index}-${section.key}`} className="flex">
              <div
                onClick={() => setActiveSectionKey(section.key)}
                className={`badge__button badge__button--watchful ${
                  activeSectionKey === section.key &&
                  "badge__button--active-" + index
                }`}
              >
                <section.Icon />

                <h5 className="badge__wording lg:text-2xl">{section.title}</h5>
              </div>
            </div>
          ))}
        </div>
        <div className="feature-box max-sm:p-10" id="feature-box">
          <div className="feature-content" id="feature-content">
            <ActiveSection.Component />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturesSection;

export const overlay = (
  <div
    className="absolute h-full w-full top-0 bottom-0 left-0 right-0"
    style={{
      backgroundColor: "rgba(0,0,0,0.3)",
    }}
  ></div>
);
