import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { overlay } from ".";

const HarnessingAI = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const width = window.innerWidth;
  return width > 800 ? (
    <div className="wrapper">
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop"
          loading="lazy"
          width="870"
          height="350"
          alt=""
          src="/media/images/analytics.jpg"
          data-loaded="true"
        />
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/analytics.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title text-white content__title--half ">
            Sleep Analytics
          </h4>
          <div className="content__description">
            Get a comprehensive view of your baby's sleep quality, with detailed
            reports on sleep duration, patterns, and disturbances
          </div>
          <div></div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/air-quality.jpg"
          loading="lazy"
          width="427"
          height="100%"
          alt=""
          style={{
            objectPosition: "top center",
          }}
          src="/media/images/air-quality.jpg"
          data-loaded="true"
        />

        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/air-quality.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title text-gray-200">Air quality</h4>
          <div className="content__description">
            Monitor and analyze air quality in real-time using our AI-powered
            system
          </div>
          {/* <a
            className="button content__button button-tertiary button-normal"
            href="https://us.getcubo.com/pages/cuboai-feature-safety#safety-micro-motion"
          >
            Learn more
          </a> */}
          <div></div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--desktop lozad"
          data-src="/media/images/vitals.jpg"
          loading="lazy"
          width="427"
          height="350"
          alt=""
          src="/media/images/vitals.jpg"
          data-loaded="true"
        />

        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/vitals.jpg"
          loading="lazy"
          width="328"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title ">Baby's Vitals</h4>
          <div className="content__description">
            Monitor your baby's vital signs with precision and peace of mind
          </div>
          <div></div>
        </div>
      </div>
    </div>
  ) : (
    <Slider {...settings} className="relative">
      <div className="feature-item feature-item-stretch">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/analytics.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title text-white content__title--half ">
            Sleep Analytics
          </h4>
          <div className="content__description">
            Get a comprehensive view of your baby's sleep quality, with detailed
            reports on sleep duration, patterns, and disturbances
          </div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/air-quality.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />
        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title text-gray-800">Air quality</h4>
          <div className="content__description">
            Monitor and analyze air quality in real-time using our AI-powered
            system
          </div>
        </div>
      </div>

      <div className="feature-item">
        {overlay}
        <img
          className="feature-item__img feature-item__img--mobile"
          src="/media/images/vitals.jpg"
          loading="lazy"
          width="100%"
          height="468"
          alt=""
        />

        <div className="feature-item-content feature-item-content-ai content">
          <h4 className="content__title ">Baby's Vitals</h4>
          <div
            className="content__description"
            style={{
              textShadow: "0px 0px 8px black",
            }}
          >
            Monitor your baby's vital signs with precision and peace of mind
          </div>
        </div>
      </div>
    </Slider>
  );
};

export default HarnessingAI;
